import React from "react";
import Slider from "react-slick";
import TicketItem from "./components/UI/ticketItem";


//   <Carousel
// 				className="bg-gradient-to-b via-blue-900 from-[#010b18] to-[#596b87] "
// 				slide={false}
// 			>
// 				{/* <MyTickets /> */}
// 				<Ticket2 />
// 				<Tickets3 />
// 			</Carousel>

const CarouselPage = ({ tickets, resetTickets }) => {

	let settings = 0;

	if(tickets.length < 4){
		 settings = {
			dots: false,
			infinite: true,
			speed: 500,
		
			slidesToShow: tickets.length, // show max 4 slides on desktop
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 768, // show 1 slide on mobile
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};
	} else{
		settings = {
			dots: false,
			infinite: true,
			speed: 500,
		
			slidesToShow: 4, // show max 4 slides on desktop
			slidesToScroll: 4,
			responsive: [
				{
					breakpoint: 768, // show 1 slide on mobile
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		};
	}

	return (
		<div className="h-auto ">
			<div className="w-full flex-col justify-center p-10 bg-transparent">
				{/* <div className="w-full grid grid-cols-4 gap-4 "> */}
				<Slider {...settings}>
					{tickets.map((event, index) =>
						event ? (
							<TicketItem
								event={event}
								key={index}
								resetTickets={resetTickets}
							/>
						) : null
					)}
				</Slider>
				{/* </div> */}
			</div>
		</div>
	);
};

export default CarouselPage;
