import React, { useState, useEffect } from 'react';
import artworkM from './assets/images/artworkM.webp';
import artwork from './assets/images/artwork.webp';
import artworkD4 from './assets/images/artworkD4.webp';
import loadinggif from './assets/utility/loading.gif';

const Hero = ({ setShowDraw }) => {
  const [activeImage, setCurrentActiveImage] = useState(loadinggif);
  const [loading, setLoading] = useState(true);

  const backgroundImageStyle = {
    background: `#000000`,
    backgroundSize: 'cover',
    backgroundImage: `url(${activeImage})`,
    transition: '0.1s background linear',
    //	filter: `${loading ? 'blur(2px)' : ''}`,
    '@media (maxWidth: 434px)': {
      backgroundImage: `image-set(  
				url(${artworkM}) 1x,  
				url(${artwork}) 2x
			  )`,
    },
  };

  useEffect(() => {
    const updateActiveImage = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 768) {
        const img = new Image();
        img.src = artworkD4;
        img.onload = () => {
          setCurrentActiveImage(artworkD4);
          setLoading(false);
        };
      } else {
        const img = new Image();
        img.src = artwork;
        img.onload = () => {
          setCurrentActiveImage(artwork);
          setLoading(false);
        };
      }
    };

    updateActiveImage();

    window.addEventListener('resize', updateActiveImage);

    return () => {
      window.removeEventListener('resize', updateActiveImage);
    };
  }, []);

  return (
    <div
      className={`w-full flex justify-center bg-no-repeat bg-cover pb-5`}
      style={backgroundImageStyle}
    >
      <div className="max-w-screen-2xl mx-auto px-3 sm:px-10 lg:min-h-screen w-full flex text-white flex-col justify-center gap-4 mb-10">
        <div className="w-full flex flex-col items-start gap-2 md:gap-3 lg:mt-50 mt-12 md:mt-0">
          <div className="w-full flex items-center justify-center md:justify-start">
            <h2 className="text-4xl md:text-6xl px-5 py-3 font-tcbbold via-transparent md:py-9 bg-gradient-to-r from-blue-900 to-pink-900 font-bold uppercase">
              Change your life
            </h2>
          </div>
          <div className="w-full flex items-center justify-center md:justify-start">
            <h2 className="text-4xl md:text-6xl font-tcbbold px-5 py-3 via-transparent md:py-9 bg-gradient-to-r from-blue-900 to-pink-900 font-bold uppercase">
              with just a few simple spins!
            </h2>
          </div>
          <div className="max-w-screen-md flex justify-center items-center">
            <p className="w-full heroblackbackground text-xl lg:text-1xl mt-10 mb-7 font-tcbbold text-left">
              Experience the thrill of winning with Luckblocks: Join the
              revolutionary decentralized lottery powered by Polygon blockchain.
              Earn USDT revenue by being an NFT draw executor or hit jackpots in
              BTC, ETH, POL, and CRANNI. Get ready to unlock your luck today!
            </p>
          </div>
        </div>
        <div className="w-full flex justify-center sm:justify-start mb-8">
          <button
            onClick={setShowDraw}
            className="text-lg font-tcbregular italic text-white sm:w-[400px] w-[400px] sm:h-[70px] h-[40px] font-bold sm:text-2xl rounded-[39px] bg-gradient-to-r from-[#13EBFD] to-[#F00FE8] hover:opacity-90 active:opacity-80"
          >
            PLAY NOW!
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
