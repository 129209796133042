import { shortAddress, timeConverter } from '../../../utils';
import { useState, React, useEffect } from 'react';
import { ethers } from 'ethers';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import '@sweetalert2/theme-dark/dark.css';
import Tooltip from '../Tooltip/onButton';
import info from '../../../assets/utility/info.svg';
import bitcoin from '../../../assets/utility/bitcoin.png';
import ethereum from '../../../assets/utility/ethereum.png';
import matic from '../../../assets/utility/matic.png';
import CRANNI from '../../../assets/utility/CRANNI.png';
import luckblocksLogo from '../../../assets/images/luckblocks.png';
import { writeContract } from '@wagmi/core';
import {
  luckBlocksAddress,
  ethLuckBlocksAddress,
  maticLuckBlocksAddress,
  CRANNILuckBlocksAddress,
} from '../../../.config';
import LuckBlocks from '../../../abi/LuckBlocks.json';
import { useAccount } from 'wagmi';

const TicketItem = ({ event, resetTickets }) => {
  const { connector: activeConnector } = useAccount();
  const [clicked, setClicked] = useState(false);
  const [resetted, setResetted] = useState(false);

  async function forfeitTicket(lottery, id) {
    setClicked(!clicked);
    let contractAddress;

    if (lottery === 'Bitcoin') {
      contractAddress = luckBlocksAddress;
    } else if (lottery === 'Ethereum') {
      contractAddress = ethLuckBlocksAddress;
    } else if (lottery === 'Matic') {
      contractAddress = maticLuckBlocksAddress;
    } else if (lottery === 'CRANNI') {
      contractAddress = CRANNILuckBlocksAddress;
    }

    const { hash } = await writeContract({
      address: contractAddress,
      abi: LuckBlocks.abi,
      functionName: 'ForfeitTicket',
      args: [id],
    });

    // Additional code you may want to include after calling the contract
    // For example, you can handle the transaction hash or perform other actions

    // Example log
    console.log('Forfeit Ticket Hash:', hash);

    // Wait for the transaction to be confirmed
    const receipt = await waitForTransactionConfirmation(hash);

    // Check if the transaction was successful
    if (receipt.status === 1) {
      console.log('Transaction successful!');
      Swal.fire({
        title: 'Success',
        text: 'Transaction successfull. Hash: ' + hash,
        icon: 'success',
      });
      setTimeout(resetTickets(), 3000);
    } else {
      console.log('Transaction failed!');
      // Handle failed transaction
      Swal.fire({
        title: 'Danger',
        text: 'Failed: Transaction failed, try again.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  const waitForTransactionConfirmation = async (hash) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const transactionReceipt = await provider.waitForTransaction(hash);
    return transactionReceipt;
  };

  /*

	useEffect(() => {

		const handleConnectorUpdate = ({ account, chain }) => {
			if (account || chain) {
				if (!resetted) {
					setResetted(true);
					setTimeout(() => {
						resetTickets();
						setResetted(false);
					}, 3000);
				}
			}
		};

		if (activeConnector) {
			activeConnector.off('change', handleConnectorUpdate);
			activeConnector.on('change', handleConnectorUpdate);
		}

		return () => {
			activeConnector.off('change', handleConnectorUpdate);
		};
	}, [activeConnector]); */

  if (event) {
    return (
      <div className="w-full lg:w-[250px] h-auto border-2 rounded-2xl p-5 bg-black border-amber-400">
        <div className="flex text-white font-bold justify-between items-center mb-9 ">
          {event.lottery === 'Bitcoin' ? (
            <img
              src={bitcoin ? bitcoin : ''}
              alt="bitcoin"
              className="w-10 border-2 border-white rounded-full p-1"
            />
          ) : event.lottery === 'Ethereum' ? (
            <img
              src={ethereum ? ethereum : ''}
              alt="ethereum"
              className="w-10 border-2 border-white rounded-full p-1"
            />
          ) : event.lottery === 'Matic' ? (
            <img
              src={matic ? matic : ''}
              alt="matic"
              className="w-10 border-2 border-white rounded-full p-1"
            />
          ) : (
            <img
              src={CRANNI ? CRANNI : ''}
              alt="CRANNI"
              className="w-10 border-2 border-white rounded-full p-1"
            />
          )}
          Ticket #{event.ticketId}
        </div>

        <img src={luckblocksLogo} className="w-full bg-black mb-2 mt-2" />

        <h3 className="w-full flex font-tcbregular italic text-2xl text-[#8E90FF]  justify-center  mb-2">
          VALID FOR DRAW ID:
        </h3>
        <h3 className="flex text-white justify-center items-center mb-4">
          {event.drawId}
        </h3>
        {event.weekly !== 3 ? (
          <>
            <h3 className="w-full flex font-tcbregular font-bold italic text-2xl text-[#8E90FF]  justify-center  mb-2">
              DRAWING AT:
            </h3>
            <h3 className="flex text-white justify-center items-center text-center mb-4">
              {timeConverter(event.nextSpin / 1000)}
            </h3>

            <h3 className="w-full flex font-tcbregular italic text-2xl text-[#8E90FF]  justify-center  mb-2 ">
              ON JACKPOT WIN:
            </h3>
            <h3 className="font-extrabold text-3xl justify-center text-transparent text-center bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
              {event.jackpotWinC}{' '}
              {event.lottery === 'Bitcoin' ? (
                <span className="notranslate font-extrabold text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box ">
                  {' '}
                  BTC{' '}
                </span>
              ) : event.lottery === 'Ethereum' ? (
                <span className="notranslate font-extrabold text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box ">
                  {' '}
                  ETH{' '}
                </span>
              ) : event.lottery === 'Matic' ? (
                <span className="notranslate font-extrabold text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box ">
                  {' '}
                  POL{' '}
                </span>
              ) : (
                <span className="notranslate font-extrabold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box ">
                  {' '}
                  CRANNI{' '}
                </span>
              )}
            </h3>
            <h3 className="notranslate w-full flex justify-center mb-4 text-white">
              ~{event.jackpotWinU} USD
            </h3>
          </>
        ) : (
          <>
            <h3 className="w-full flex font-tcbregular font-bold italic text-2xl text-[#8E90FF]  justify-center  mb-2">
              DRAW WINNER:
            </h3>
            <h3 className="flex text-white justify-center items-center text-center mb-4">
              {' '}
              {event.winner === true ? '🟢' : '🔴'}
            </h3>
          </>
        )}
        <div className="flex flex-col">
          <h3 className="w-full flex font-tcbregular italic text-2xl text-[#8E90FF]  justify-center mb-2 border-6 border-amber-400">
            OWNER:
          </h3>
          <h3 className="flex text-white justify-center items-center text-center mb-2">
            {shortAddress(event.owner)}
          </h3>
        </div>

        <div className="flex w-full">
          {event.weekly === 0 ? (
            <>
              <button
                onClick={() => forfeitTicket(event.lottery, event.ticketId)}
                className={`custom-button ${
                  clicked ? 'clicked' : ''
                } mx-auto mt-8 flex font-bold sm:text-2xl text-center items-center justify-center rounded-[15px] bg-amber-400 text-sm font-tcbregular italic text-[#000000] mb-12 sm:w-[400px] w-[200px] lg:w-[400px] mx-auto sm:h-[70px] h-[40px]`}
              >
                <Tooltip
                  message={'You will receive 75% of Ticket Value in Return.'}
                >
                  FORFEIT TICKET{' '}
                  <img src={info} alt="info" className="w-4 h-4" />
                </Tooltip>
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
};

export default TicketItem;
