import React, { useEffect, useState, Fragment, Suspense, lazy } from 'react';
import { ethers } from 'ethers';
import {
  nftNodes,
  nftNodesCFG,
  cranniAddress,
  MultiCall,
  wsprovider,
  provider,
  bckpProvider,
  luckBlocksAddress,
  luckBlocksWAddress,
  ethLuckBlocksAddress,
  ethluckBlocksWAddress,
  maticLuckBlocksAddress,
  maticLuckBlocksWAddress,
  CRANNILuckBlocksAddress,
  CRANNILuckBlocksWAddress
} from '../.config';
import { shortAddress, timeConverter } from '../utils';
import luckBlocksNodes from '../abi/luckBlocksNodes.json';
import luckBlocksNodesCFG from '../abi/luckBlocksNodesCFG.json';
import Multicall from '../abi/Multicall.json';
import { erc20ABI } from 'wagmi';
import btc from '../assets/utility/bitcoin.png';
import eth from '../assets/utility/ethereum.png';
import matic from '../assets/utility/matic.png';
import CRANNI from '../assets/utility/CRANNI.png';
import { useAccount, useBalance } from 'wagmi';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import '@sweetalert2/theme-dark/dark.css';
import { writeContract } from '@wagmi/core';
import crannigif from '../assets/utility/crannigifs.gif';
import opensea from '../assets/utility/opensea.webp';
import rarible from '../assets/utility/rarible.webp';

const NFTLogs = lazy(() => import('../NFTLogs'));
const CarouselInWallet = lazy(() => import('../CarouselINWallet'));
const CarouselInStaked = lazy(() => import('../CarouselINStaked'));

const NFTnodes = () => {
  const [loading, setLoading] = useState(true);
  const [pageChange, setPageChange] = useState(true);
  const [isApproved, setIsApproved] = useState(false);
  const [myStatusC, setStatusC] = useState(false);
  const [myStatusG, setStatusG] = useState(false);
  const [txs, setTxs] = useState([]);
  const [nftsInWallet, setNFTs] = useState([]);
  const [nftsStaked, setStakedNFTs] = useState([]);
  const [contractWatched, setContractWatched] = useState("Bitcoin");
  const [dailyContract, setDailyContract] = useState(luckBlocksAddress);
  const [weeklyContract, setWeeklyContract] = useState(luckBlocksWAddress);
  const [activeQueue, setAQ] = useState([]);
  const [waitingQueue, setWQ] = useState([]);
  const [activeQueueG, setAQG] = useState([]);
  const [waitingQueueG, setWQG] = useState([]);
  const [nextSpins, setNS] = useState([]);
  const [nextSpinsW, setNSW] = useState([]);
  const [salePhase, setPhase] = useState(0);
  const [price, setPrice] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [remainingOnPhase, setRemainingPhase] = useState(0);
  const [page, setPage] = useState(1);
  const { address, isConnected } = useAccount();
  const { data } = useBalance({
    address: address,
    token: cranniAddress, // CRANNI contract address
  });
  const [isMobile, setIsMobile] = useState(false);

  let nftsArray = [];
  let nftsStakedArray = [];

  const resetNfts = () => {
    nftsArray = [];
    nftsStakedArray = [];
    setNFTs([]);
    setStakedNFTs([]);
    setStatusC(false);
    setStatusG(false);
    setLoading(true);
    setTimeout(() => getQueues(), 2000);
    setTimeout(() => getMyNFTs(), 4000);
  };


  const changeLotteryToBTC = () => {
    setContractWatched("Bitcoin");
    setDailyContract(luckBlocksAddress);
    setWeeklyContract(luckBlocksWAddress);
  };

  const changeLotteryToETH = () => {
    setContractWatched("Ethereum");
    setDailyContract(ethLuckBlocksAddress);
    setWeeklyContract(ethluckBlocksWAddress);
  };

  const changeLotteryToMATIC = () => {
    setContractWatched("Matic");
    setDailyContract(maticLuckBlocksAddress);
    setWeeklyContract(maticLuckBlocksWAddress);
  };
  const changeLotteryToCRANNI = () => {
    setContractWatched("CRANNI");
    setDailyContract(CRANNILuckBlocksAddress);
    setWeeklyContract(CRANNILuckBlocksWAddress);
  };

  const contract = new ethers.Contract(nftNodes, luckBlocksNodes.abi, provider);
  const contractCFG = new ethers.Contract(
    nftNodesCFG,
    luckBlocksNodesCFG.abi,
    wsprovider
  );
  const contractCFGbckp = new ethers.Contract(
    nftNodesCFG,
    luckBlocksNodesCFG.abi,
    bckpProvider
  );
  const multicontract = new ethers.Contract(
    MultiCall,
    Multicall.abi,
    bckpProvider
  );

  const spinStyle = {
    backgroundColor: 'black',
    color: 'white',
    padding: '10px',
    borderRadius: '10px',
    weight: 500,
    family: 'tcbbold',
    size: 10,
    marginBottom: isMobile ? '10px' : '0',
  };

  //

  // Mint Page
  async function checkForApproval() {
    if (isConnected) {
      const contractToken = new ethers.Contract(
        cranniAddress,
        erc20ABI,
        provider
      );
      let approval = await contractToken.allowance(address, nftNodes);

      approval = ethers.utils.formatUnits(approval.toString(), 8);

      if (parseFloat(approval) > 50) {
        setIsApproved(true);
      } else {
        setIsApproved(false);
      }
    }
  }

  async function approveToSpendCRANNI() {
    const approvalAmount = ethers.utils.parseUnits('1000', 8);

    const { hash } = await writeContract({
      address: cranniAddress,
      abi: erc20ABI,
      functionName: 'approve',
      args: [nftNodes, approvalAmount],
    });

    // Wait for the transaction to be confirmed
    const receipt = await waitForTransactionConfirmation(hash);

    // Check if the transaction was successful
    if (receipt.status === 1) {
      console.log('Transaction successful!');
      setIsApproved(true);
      Swal.fire({
        title: 'Success',
        text: 'Approval was successful, Now you begin Mint',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      console.log('Transaction failed!');
      // Handle failed transaction
      Swal.fire({
        title: 'Danger',
        text: 'Failed: Transaction failed, try again.',
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async function handleMint() {
    if (isConnected) {
      let balanceInMatic = 0;

      await provider.getBalance(address).then((balance) => {
        // convert a currency unit from wei to ether
        balanceInMatic = balance;
      });

      const crannibalance = parseFloat(data?.formatted).toFixed(3);

      if (crannibalance < 50) {
        Swal.fire({
          title: 'Error',
          text: 'Error: not enough amount of CRANNI tokens in your wallet.',
          icon: 'error',
        });
        return;
      }

      if (salePhase > 1) {
        if (parseFloat(balanceInMatic) < parseFloat(price)) {
          Swal.fire({
            title: 'Error',
            text: 'Error: not enough amount of MATIC to mint NFT.',
            icon: 'error',
          });
          return;
        }
      }

      if (remainingOnPhase < 1) {
        Swal.fire({
          title: 'Error',
          text: "Error: There's no NFT to mint on this phase.",
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }

      if (remaining < 1) {
        Swal.fire({
          title: 'Error',
          text: 'Error: All NFTs has been minted, buy from other users on OpenSea or other market.',
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }

      if (salePhase < 1) {
        Swal.fire({
          title: 'Info',
          text: 'Whitelist Phase, make sure you are whitelisted to mint NFT.',
          icon: 'info',
          showConfirmButton: false,
          timer: 5000,
        });
      }

      const { hash } = await writeContract({
        address: nftNodes,
        abi: luckBlocksNodes.abi,
        functionName: 'mint',
        value: price,
      });

      // Wait for the transaction to be confirmed
      const receipt = await waitForTransactionConfirmation(hash);

      // Check if the transaction was successful
      if (receipt.status === 1) {
        console.log('Transaction successful!');
        // Perform additional actions or show success message
        Swal.fire({
          title: 'Success',
          text: 'Successfully Minted NFT, check on your wallet and/or dashboard page.',
          icon: 'success',
          showConfirmButton: false,
          timer: 2000,
        });
        document.getElementById('bodyscroll').style.overflow = 'scroll';
      } else {
        console.log('Transaction failed!');
        // Handle failed transaction
        Swal.fire({
          title: 'Danger',
          text: 'Failed: Transaction failed, try again.',
          icon: 'error',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      console.log('please connect to wallet');
      Swal.fire({
        title: 'Info',
        text: 'Please, connect to your wallet provider to be able to mint NFTnodes.',
        icon: 'info',
        showConfirmButton: true,
      });
    }
  }

  const waitForTransactionConfirmation = async (hash) => {
    const transactionReceipt = await provider.waitForTransaction(hash);
    return transactionReceipt;
  };

  async function getMintData() {
    const getphase = await contract.currentPhase();
    let currentPrice = await contract.price();
    const remainingPhase = await contract.remainingonphase();
    const totalSupply = await contract.totalSupply();

    const totalRemaining = 50 - totalSupply;

    //	currentPrice = ethers.utils.formatUnits(currentPrice.toString(),"ether");

    //		console.log(currentPrice);

    setRemaining(totalRemaining.toString());
    setRemainingPhase(remainingPhase.toString());
    setPhase(getphase.toString());
    setPrice(currentPrice);
  }

  // Dashboard Page
  // Helper function to retrieve node information asynchronously
  async function getNodeInfo(i) {
    const checkNode = await contractCFGbckp.nodes(i);
    const nodeId = i;
    const nodeProfit = checkNode.totalProfit;

    // For each contract
    const getStatus = await contractCFG.getNodeStatus(i, luckBlocksAddress);

    return { nodeId, nodeProfit, getStatus };
  }

  const getNodeInfoWithDelay = (i) => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const nodeInfo = await getNodeInfo(i);
        resolve(nodeInfo);
      }, i * 200); // Multiply the index by 20 milliseconds (0.20 seconds)
    });
  };

  async function getMyNFTs() {
    if (isConnected) {
      // Get Staked NFTs

      // new
      const stakedNfts = await contractCFG.getStakedNodesFromUser(address);
      const convertedStakedNfts = stakedNfts.map((bytes32Value) =>
        ethers.utils.hexlify(bytes32Value)
      );

      // Create an array to hold all the promises
      const promises = [];

      for (const staked of convertedStakedNfts) {
        const stakedId = ethers.BigNumber.from(staked).toNumber();

        promises.push(getNodeInfoWithDelay(stakedId));
      }

      // Use Promise.all to await all promises
      const results = await Promise.all(promises);

      // Process the results
      for (const result of results) {
        const { nodeId, nodeProfit } = result;

        let getStatus;

        if (nodeId < 11) {
          getStatus = await contractCFG.getNodeStatus(nodeId, weeklyContract)
        } else {
          getStatus = await contractCFG.getNodeStatus(nodeId, dailyContract);
        }
        const status = getStatus !== 'notInActive';

      //  console.log("NODE ", nodeId, " STATUS: ", status);

        nftsStakedArray.push({
          id: nodeId,
          totalProfit: parseFloat(
            ethers.utils.formatUnits(nodeProfit, 6)
          ).toFixed(2),
          status: status,
        });
      }

      setStakedNFTs(nftsStakedArray);
      //
      // get wallet NFTs
      const userNfts = await contract.getTokensFromAddress(address);

      const convertedUserNfts = userNfts.map((bytes32Value) =>
        ethers.utils.hexlify(bytes32Value)
      );

      for (const nft of convertedUserNfts) {
        const nftId = ethers.BigNumber.from(nft).toNumber();

        nftsArray.push({
          id: nftId,
        });
      }

      setNFTs(nftsArray);

      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  async function getQueues() {

    // get queues of nodes

    /*  changed to get per contract */
    const [activenodes, activenodesG, waitingnodes, waitingnodesG] =
      await Promise.all([
        contractCFG
          .getActiveNodes(dailyContract)
          .then((values) => values.map((value) => '#' + value)),
        contractCFG
          .getActiveNodes(weeklyContract)
          .then((values) => values.map((value) => '#' + value)),
        contractCFG
          .getWaitingNodes(dailyContract)
          .then((values) => values.map((value) => '#' + value)),
        contractCFG
          .getWaitingNodes(weeklyContract)
          .then((values) => values.map((value) => '#' + value)),
      ]);

    setAQ(activenodes);
    setWQ(waitingnodes);
    setAQG(activenodesG);
    setWQG(waitingnodesG);

    //user active status
    if (isConnected) {

      // changed per contract - (user,contract)

      const userStatus = await contractCFG.getUserActivation(address, dailyContract);
      const userStatusG = await contractCFG.getUserActivation(address, weeklyContract);

      setStatusC(userStatus);
      setStatusG(userStatusG);

    }
    //

    // get next spins
    const lotteryInfos = await multicontract.getLotteryInfos();
    const lotteryInfosW = await multicontract.getLotteryInfosW();

    const getNextSpinBTC = lotteryInfos[0][2] * 1000 + 86400000;
    const getNextSpinWeeklyBTC = lotteryInfosW[0][2] * 1000 + 604800000;
    const getNextSpinETH = lotteryInfos[1][2] * 1000 + 86400000;
    const getNextSpinWeeklyETH = lotteryInfosW[1][2] * 1000 + 604800000;
    const getNextSpinMATIC = lotteryInfos[2][2] * 1000 + 86400000;
    const getNextSpinWeeklyMATIC = lotteryInfosW[2][2] * 1000 + 604800000;
    const getNextSpinCRANNI = lotteryInfos[3][2] * 1000 + 86400000;
    const getNextSpinWeeklyCRANNI = lotteryInfosW[3][2] * 1000 + 604800000;

    const nextSpinsArray = [];
    const nextSpinsArrayW = [];

    for (let i = 0; i < 8; i++) {
      let coinImage;
      let timestamp;

      switch (i) {
        case 0:
          coinImage = 'btc';
          timestamp = parseInt(getNextSpinBTC);
          break;
        case 1:
          coinImage = 'eth';
          timestamp = parseInt(getNextSpinETH);
          break;
        case 2:
          coinImage = 'matic';
          timestamp = parseInt(getNextSpinMATIC);
          break;
        case 3:
          coinImage = 'CRANNI';
          timestamp = parseInt(getNextSpinCRANNI);
          break;
        case 4:
          coinImage = 'btc';
          timestamp = parseInt(getNextSpinWeeklyBTC);
          break;
        case 5:
          coinImage = 'eth';
          timestamp = parseInt(getNextSpinWeeklyETH);
          break;
        case 6:
          coinImage = 'matic';
          timestamp = parseInt(getNextSpinWeeklyMATIC);
          break;
        case 7:
          coinImage = 'CRANNI';
          timestamp = parseInt(getNextSpinWeeklyCRANNI);
          break;
        // Add more cases for additional coins
        default:
          coinImage = null; // Provide a default value if needed
          break;
      }

      if (i < 4) {
        nextSpinsArray.push({
          coin: coinImage,
          time: timestamp,
        });
      } else {
        nextSpinsArrayW.push({
          coin: coinImage,
          time: timestamp,
        });
      }
    }

    setNS(nextSpinsArray);
    setNSW(nextSpinsArrayW);
    setPageChange(false);
  }

  // Logs
  async function loadLogs() {
    const currentBlock = await wsprovider.getBlockNumber();
    const startBlock = currentBlock - 45000; // -83200
    let eventFilter = contractCFG.filters.NftRewarded();
    const items = await contractCFGbckp.queryFilter(
      eventFilter,
      startBlock,
      currentBlock
    );

    /*
    let weventFilter = Whttpcontract.filters.LotteryLog();
    const Witems = await Whttpcontract.queryFilter(weventFilter, startBlock, currentBlock);

    const filteredItems = Witems.filter(function (element) {
      return (
        element.args[2] ===
        "We have a new Weekly Raffle Winner"
      );
    });
    // setTxs(items);
    // console.log("filteredItems", filteredItems);
    if (filteredItems.length > 0) {
      items.push(filteredItems);
    }
    */
    //	console.log(items);
    setTxs(items);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize); // Update value on window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    loadLogs();

    if (page === 2) {
      checkForApproval();
      getMintData();
    }

    if (page === 3) {
      setPageChange(true);
      getMyNFTs();
      getQueues();
    }
  }, [page]);

  useEffect(() => {
    contractCFG.on('UserActivated', handleActivatedEvent);
    contractCFG.on('NftRewarded', handleActivatedEvent);

    return () => {
      // Cleanup: Unsubscribe the event handler when the component unmounts
      contractCFG.off('UserActivated', handleActivatedEvent);
      contractCFG.off('NftRewarded', handleActivatedEvent);
    };
  }, []); // Empty dependency array to run the effect only once

  // Define the event handler functions
  function handleActivatedEvent() {
    setTimeout(() => getQueues(), 1000);
    setTimeout(() => loadLogs(), 3000);
  }

  useEffect(() => {
    if (pageChange === false) {
      resetNfts();
    }
  }, [address, isConnected]);

  useEffect(() => {
    if (pageChange === false) {
      setTimeout(() => getQueues(), 2000);
      setTimeout(() => getMyNFTs(), 4000);
    }
  }, [contractWatched]);

  return (
    <>
      <div className="w-full flex justify-center bg-gradient-to-b via-[#202e44] from-black to-[#596b87]">
        <div className="playcard max-w-screen-lg w-full rounded-[29px] mb-5">
          <div className="justify-center m-4 ml-1 p-1 flex flex-wrap items-center">
            <button
              onClick={() => setPage(1)}
              className={`${page === 1 ? 'bg-white text-black' : 'bg-black text-white'
                } mr-2 w-[100px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-md font-tcbregular italic`}
            >
              About
            </button>
            <button
              onClick={() => setPage(2)}
              className={`${page === 2 ? 'bg-white text-black' : 'bg-black text-white'
                } mr-2 w-[100px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-md font-tcbregular italic`}
            >
              Mint/Buy
            </button>
            <button
              onClick={() => setPage(3)}
              className={`${page === 3 ? 'bg-white text-black' : 'bg-black text-white'
                } w-[100px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-md font-tcbregular italic`}
            >
              Dashboard
            </button>
          </div>
          <div className="w-full">
            {page === 1 ? (
              <>
                <h2 className="text-4xl text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                  About
                </h2>
                <p className="text-2xl p-5 text-center mt-5 text-white font-tcbbold italic mt-10 mb-7">
                  Cranni Societas is an elite society of 50 powerful individuals
                  who have earned their place through their exceptional skills
                  and contributions to their respective fields. The members of
                  Cranni Societas are depicted as skulls dressed in suits and
                  ties, each unique in appearance, representing their area of
                  expertise. Earning them the role of executors and executives
                  of Luckblocks Gaming DAPP.
                </p>
                <h2 className="text-4xl p-2 text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                  NFTs with Fundamental Utility
                </h2>
                <p className="text-2xl p-5 text-center mt-5 text-white font-tcbbold italic mt-10 mb-7">
                  They possess great utility, as they allow their owners to
                  access special privileges and exclusive benefits on the
                  Luckblocks platform. We call them NFTnodes because each NFT is
                  the key to activate the autospin function on the lotteries
                  smart contract, decentralizing the automation otherwise not
                  possible on EVM, with intelligent programming and monetary
                  incentive available to the whole web3 community. So what are
                  you waiting for? Be part of an exclusive web3 community, earn
                  revenue, and become a guardian of an important function on the
                  Luckblocks platform.
                </p>
                <h4 className="text-2xl text-center mt-5 text-amber-400 font-bold font-tcbbold italic mt-10 mb-7">
                  <a
                    href="https://docs.luckblocks.com/CRANNI-societas-nftnodes"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more about it on its Docs Page
                  </a>
                </h4>
              </>
            ) : page === 2 ? (
              <>
                <p className="text-white text-xl lg:text-2xl mt-4 mb-4 text-center p-10">
                  There are 3 phases with 17 NFTs in the first phase and 15 in
                  subsequent phases:
                  <br />
                  <br />
                  Phase 0 (Whitelist) -{' '}
                  <span className="text-amber-400">01/07 - 10/07</span> - Price:{' '}
                  <span className="text-purple-500">50 MATIC + 50 CRANNI</span>
                  <br />
                  Phase 1 -{' '}
                  <span className="text-amber-400">10/07 - 20/07</span> - Price:{' '}
                  <span className="text-purple-500">100 MATIC + 50 CRANNI</span>
                  <br />
                  Phase 2 -{' '}
                  <span className="text-amber-400">20/07 - 30/07+</span> -
                  Price:{' '}
                  <span className="text-purple-500">200 MATIC + 50 CRANNI</span>
                  <br />
                  <br />
                  Unsold NFTs from the previous phase will be carried over to
                  Phase 2
                </p>
                <div className="flex flex-col lg:flex-row">
                  <div className="lg:w-1/2 lg:pr-4">
                    <img
                      src={crannigif}
                      alt="Cranni Societas GIF"
                      className="w-full h-auto mb-4 lg:mb-0"
                    />
                  </div>
                  <div className="lg:w-1/2 lg:pl-4">
                    <p className="w-full heroblackbackground text-white text-xl lg:text-2xl mt-10 mb-7 text-left">
                      Mint an NFT and unlock the excitement of randomness!
                      <br />
                      With each mint, you have a chance to receive one of the
                      unique NFTs.
                      <br />
                      <br />
                      There are 7 NFTnode{' '}
                      <span className="text-amber-400">Golden</span> and 40
                      NFTnode <span className="">Common</span> NFTs waiting to
                      be discovered!
                      <br />
                      <br />
                      <span className="text-emerald-400"> Current Phase</span> <span className="text-emerald-600">{'>>'} </span> {salePhase}
                      <br />
                      <br />
                      <span className="text-emerald-400"> NFTs Remaining in that Phase</span> <span className="text-emerald-600">{'>>'} </span> {remainingOnPhase}
                      <br />
                      <span className="text-emerald-400"> Total NFTs Remaining </span> <span className="text-emerald-600">{'>>'} </span> {remaining}
                      <br />
                      <br />
                    </p>
                    {!isApproved ? (
                      <button
                        onClick={() => approveToSpendCRANNI()}
                        className="hover:bg-green-400 mx-auto mt-8 flex font-bold  text-center items-center justify-center  rounded-[39px] bg-[#F00FE8] bg-gradient-to-r from-[#13EBFD] text-md font-tcbregular italic text-[#FFFFFF] mb-12 sm:w-[400px] w-[200px] lg:w-[400px] mx-auto sm:h-[70px] h-[40px]"
                      >
                        Approve Spending
                      </button>
                    ) : (
                      <button
                        onClick={() => handleMint()}
                        className="hover:bg-green-400 mx-auto mt-8 flex font-bold  text-center items-center justify-center  rounded-[39px] bg-[#F00FE8] bg-gradient-to-r from-[#13EBFD] text-md font-tcbregular italic text-[#FFFFFF] mb-12 sm:w-[400px] w-[200px] lg:w-[400px] mx-auto sm:h-[70px] h-[40px]"
                      >
                        Mint NFT
                      </button>
                    )}
                  </div>
                </div>
                <div>
                  <p className="w-full text-white text-xl lg:text-2xl mt-10 mb-7 text-center">
                    {' '}
                    Or Buy from Other People on Decentralized Marketplaces:{' '}
                  </p>
                  <p className="w-full heroblackbackground text-white text-xl lg:text-2xl mt-10 mb-7 text-center">
                    Buy On{' '}
                    <a
                      href="{openseaLink}"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={opensea}
                        alt="OpenSea"
                        className="inline-block h-10 mr-2"
                      />
                    </a>
                  </p>
                  <p className="w-full heroblackbackground text-white text-xl lg:text-2xl mt-10 mb-7 text-center">
                    Buy On{' '}
                    <a
                      href="{raribleLink}"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={rarible}
                        alt="Rarible"
                        className="inline-block h-10 mr-2"
                      />
                    </a>
                  </p>
                </div>
              </>
            ) : (
              <>
                {isConnected ? (
                  <>
                    <div className="w-full">
                      <div className="m-5 p-5 flex flex-wrap items-center">
                        <button
                          onClick={changeLotteryToBTC}
                          className={`${dailyContract === luckBlocksAddress
                            ? 'bg-amber-400 text-black'
                            : 'bg-black text-amber-400'
                            } mr-2 w-[50px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-xs font-tcbregular italic`}
                        >
                          <img src={btc} height="35px" width="35px"></img>
                        </button>
                        <button
                          onClick={changeLotteryToETH}
                          className={`${dailyContract === ethLuckBlocksAddress
                            ? 'bg-amber-400 text-black'
                            : 'bg-black text-amber-400'
                            } mr-2 w-[50px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-xs font-tcbregular italic`}
                        >
                          <img src={eth} height="35px" width="35px"></img>
                        </button>
                        <button
                          onClick={changeLotteryToMATIC}
                          className={`${dailyContract === maticLuckBlocksAddress
                            ? 'bg-amber-400 text-black'
                            : 'bg-black text-amber-400'
                            } mr-2 w-[50px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-xs font-tcbregular italic`}
                        >
                          <img src={matic} height="35px" width="35px"></img>
                        </button>
                        <button
                          onClick={changeLotteryToCRANNI}
                          className={`${dailyContract === CRANNILuckBlocksAddress
                            ? 'bg-amber-400 text-black'
                            : 'bg-black text-amber-400'
                            } w-[50px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-xs font-tcbregular italic`}
                        >
                          <img src={CRANNI} height="35px" width="35px"></img>
                        </button>
                      </div>
                      <div className="flex flex-wrap p-5">
                        <h3 className="flex text-white justify-center items-center text-center mr-2 mb-2">
                          My Status Common Queue:{' '}
                          {myStatusC === true ? '🟢' : '🔴'}
                        </h3>
                        <h3 className="flex text-white justify-center items-center text-center mb-2">
                          My Status Golden Queue:{' '}
                          {myStatusG === true ? '🟢' : '🔴'}
                        </h3>

                        <h3 className="flex text-white justify-center items-center text-center ml-5 mb-2">
                          On Draw:{'  '}
                          <span className='ml-5 text-indigo-400'>{contractWatched}</span>
                        </h3>
                      </div>
                      <div className="flex flex-wrap">
                        <div className="w-full md:w-3/4 p-5">
                          <h2 className="text-[30px] md:text-4xl text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                            Staked NFTnodes
                          </h2>
                          {loading ? (
                            <div className="text-center mb-2">
                              <div role="status">
                                <svg
                                  aria-hidden="true"
                                  className="inline w-[150px] h-[150px] mr-2 text-transparent animate-spin fill-purple-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          ) : nftsStaked.length > 0 ? (
                            <Suspense>
                              <CarouselInStaked
                                nfts={nftsStaked}
                                resetNfts={resetNfts}
                              />
                            </Suspense>
                          ) : (
                            <div className="h-auto">
                              <div className="w-full flex-col justify-center p-10 bg-transparent">
                                <h1 className="font-bold text-center text-white items-center justify-center text-sm font-tcbregular">
                                  You don't have any Staked NFTnodes.
                                </h1>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="w-full md:w-1/4 p-5 md:p-1">
                          <h2 className="text-[30px] md:text-2xl text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                            NFTnodes in Wallet
                          </h2>
                          {loading ? (
                            <div className="text-center mb-2">
                              <div role="status">
                                <svg
                                  aria-hidden="true"
                                  className="inline w-[150px] h-[150px] mr-2 text-transparent animate-spin fill-purple-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          ) : nftsInWallet.length > 0 ? (
                            <Suspense>
                              <CarouselInWallet
                                nfts={nftsInWallet}
                                resetNfts={resetNfts}
                              />
                            </Suspense>
                          ) : (
                            <div className="h-auto">
                              <div className="w-full flex-col justify-center p-10 bg-transparent">
                                <h1 className="font-bold text-center text-white items-center justify-center text-sm font-tcbregular">
                                  You don't have any NFTnodes in your Wallet.
                                </h1>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="font-bold text-center text-white items-center justify-center text-lg font-tcbregular">
                        Learn how to configure automation with Gelato:{' '}
                        <a
                          className="text-amber-400"
                          href="https://docs.luckblocks.com/auto-spin-draw/how-to-configure-automation"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click Here
                        </a>
                      </div>

                      <div className="container p-5">
                        <div className="queue-column heroblackbackground">
                          <h1 className="text-2xl p-5 text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                            NFTnodes in active queue on <span className='text-indigo-400'>{contractWatched}</span>:
                          </h1>
                          <h2 className="text-2xl p-5 text-center mt-5 text-amber-300 font-bold font-tcbbold italic mt-10 mb-7">
                            Golden
                          </h2>
                          <div className="ml-2 max-w-full overflow-x-auto">
                            <div className="inline-flex flex-wrap">
                              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                [
                              </span>
                              {activeQueueG.map((node, index) => (
                                <React.Fragment key={node}>
                                  <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                    {node}
                                  </span>
                                  {index !== activeQueueG.length - 1 && (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                      ,&nbsp;
                                    </span>
                                  )}
                                </React.Fragment>
                              ))}
                              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                ]
                              </span>
                            </div>
                          </div>
                          <h2 className="text-2xl p-5 text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                            Common
                          </h2>
                          <div className="ml-2 max-w-full overflow-x-auto">
                            <div className="inline-flex flex-wrap">
                              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                [
                              </span>
                              {activeQueue.map((node, index) => (
                                <React.Fragment key={node}>
                                  <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                    {node}
                                  </span>
                                  {index !== activeQueue.length - 1 && (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                      ,&nbsp;
                                    </span>
                                  )}
                                </React.Fragment>
                              ))}
                              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                ]
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="queue-column heroblackbackground">
                          <h1 className="text-2xl p-5 text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                            NFTnodes in waiting queue on <span className='text-indigo-400'>{contractWatched}</span>:
                          </h1>
                          <h2 className="text-2xl p-5 text-center mt-5 text-amber-300 font-bold font-tcbbold italic mt-10 mb-7">
                            Golden
                          </h2>
                          <div className="ml-2 max-w-full overflow-x-auto">
                            <div className="inline-flex flex-wrap">
                              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                [
                              </span>
                              {waitingQueueG.map((node, index) => (
                                <React.Fragment key={node}>
                                  <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                    {node}
                                  </span>
                                  {index !== waitingQueueG.length - 1 && (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                      ,&nbsp;
                                    </span>
                                  )}
                                </React.Fragment>
                              ))}
                              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                ]
                              </span>
                            </div>
                          </div>
                          <h2 className="text-2xl p-5 text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                            Common
                          </h2>
                          <div className="ml-2 max-w-fulloverflow-x-auto">
                            <div className="inline-flex flex-wrap">
                              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                [
                              </span>
                              {waitingQueue.map((node, index) => (
                                <React.Fragment key={node}>
                                  <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                    {node}
                                  </span>
                                  {index !== waitingQueue.length - 1 && (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                      ,&nbsp;
                                    </span>
                                  )}
                                </React.Fragment>
                              ))}
                              <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                                ]
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <h1 className="text-3xl p-5 text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                          Next spins:
                        </h1>
                        <h2 className="text-2xl p-5 text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                          Daily
                        </h2>
                        <div
                          className="p-5"
                          style={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                          {nextSpins.slice(0, 4).map((spin, index) => (
                            <div
                              key={index}
                              style={{
                                ...spinStyle,
                                width: isMobile ? '100%' : 'auto',
                                marginRight: isMobile ? '0' : '10px',
                              }}
                            >
                              <img
                                src={
                                  spin.coin === 'btc'
                                    ? btc
                                    : spin.coin === 'eth'
                                      ? eth
                                      : spin.coin === 'matic'
                                        ? matic
                                        : CRANNI
                                }
                                alt="coinLogo"
                                className="w-10 border-2 border-white rounded-full p-1"
                              />{' '}
                              <p className="text-[20px] p-2  text-center mt-2 text-white font-bold font-tcbbold italic mb-2">
                                : {timeConverter(spin.time / 1000)}
                              </p>
                            </div>
                          ))}
                        </div>

                        <h2 className="text-2xl p-5 text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                          Weekly
                        </h2>
                        <div
                          className="p-5"
                          style={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                          {nextSpinsW.slice(0, 4).map((spin, index) => (
                            <div
                              key={index}
                              style={{
                                ...spinStyle,
                                width: isMobile ? '100%' : 'auto',
                                marginRight: isMobile ? '0' : '10px',
                              }}
                            >
                              <img
                                src={
                                  spin.coin === 'btc'
                                    ? btc
                                    : spin.coin === 'eth'
                                      ? eth
                                      : spin.coin === 'matic'
                                        ? matic
                                        : CRANNI
                                }
                                alt="coinLogo"
                                className="w-10 border-2 border-white rounded-full p-1"
                              />{' '}
                              <p className="text-[20px] p-2 text-center mt-2 text-white font-bold font-tcbbold italic mb-2">
                                : {timeConverter(spin.time / 1000)}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="h-auto ">
                      <div className="w-full flex-col justify-center p-10 bg-transparent">
                        <h1 className="font-bold text-center text-white items-center justify-center text-md font-tcbregular">
                          Connect to your wallet to see your NFTnodes.
                        </h1>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b via-[#202e44] from-[#596b87] to-[#596b87]">
        <Suspense>
          <NFTLogs transactions={txs} />
        </Suspense>
      </div>
    </>
  );
};

export default NFTnodes;
