import { ethers } from "ethers";
import { Network, Alchemy } from 'alchemy-sdk';

// Optional Config object, but defaults to demo api-key and eth-mainnet.
const settings = {
  apiKey: 'rH_6eF58MmxmyBy3FGdKCTYvUSnBizxH', // Replace with your Alchemy API Key.
  network: Network.MATIC_MUMBAI, // Replace with your network.
};

const alchemy = new Alchemy(settings);

// moralis node https://site1.moralis-nodes.com/bsc-testnet/be348eecea6643b8a11d9df4d1e939a2

// BSC TESTNET
export const luckBlocksAddress = "0x9e4bBEBfD400489de672eedD619AE10470181BF1";
export const luckBlocksWAddress = "0xFAa22D1155b2d8cC14BED0f6c33A8bFDF2F0A91d";
export const ethLuckBlocksAddress = "0x1Bd462135a165eFE16E390C43D499Dd08064766F";
export const ethluckBlocksWAddress = "0x2a1C657b671c9803eC9C000c5456b21a68cA2571";
export const maticLuckBlocksAddress = "0x2167d9F718c3C41A84715302D504DF1d94239488";
export const maticLuckBlocksWAddress = "0xceD69a43c3Cd2B7625D39A0005cAA30376663aC1";
export const CRANNILuckBlocksAddress = "0x6aDe006D44391a8E71e9012390ccDf688Ed6032d";
export const CRANNILuckBlocksWAddress = "0x84145898E8C8643cd49cd651Ba2650a03815336D";
export const MultiCall = "0xD855c21e038Fe5f6146f480aDeEdD0d96d2212C3";
export const erc20Address = "0x2a3E6a9824c1f59AEFDe1f5eC4F2b4c3460C6992"; // USDT address 0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174
export const cranniAddress = "0x8C426Bc4506b4f7BfB21cF67A342e0E7EBe0980f"; // mainnet address : 0x671078C0496Fa135a8c45fC7c9FA7B1501fD5146

export const nftNodes = "0x49c3844b00AA8268E41959c18D84d56F8De5F9dc";
export const nftNodesCFG = "0x17Fc1Fe5bCD865d88a03A7031C18cCaFE8fBA427";

export const wsprovider = new ethers.providers.WebSocketProvider(
	`wss://ws-nd-964-687-235.p2pify.com/e65234f9dce3dd55ebec82a5c2988c11`
);

export const alchemyBase = alchemy;

export const provider = new ethers.providers.JsonRpcProvider(
	`https://bsc-testnet.public.blastapi.io`
);
export const bckpProvider = new ethers.providers.WebSocketProvider(
	`wss://bsc-testnet-rpc.publicnode.com`
)