import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from '../../context/useCountdown';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <p className="countdown-link">
        <DateTimeDisplay value={days} type={'Days'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
      </p>
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return (
      <h1 className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
        AT ANY MOMENT NOW!!{' '}
      </h1>
    );
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
