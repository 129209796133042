import React, { useState, useEffect } from 'react';
import polyskullL from './assets/images/polyskullL.webp';
import crimsonL from './assets/images/polyskullL.webp'; // <--- placeholder till create
import goldenfaceL from './assets/images/goldenfaceL.webp';
import dimelL from './assets/images/dimelL.webp';
import yellowneckL from './assets/images/yellowneckL.webp';
import silverhandL from './assets/images/silverhandL.webp';
import palidaL from './assets/images/palidaL.webp';
import roseL from './assets/images/roseL.webp';
import peevishL from './assets/images/peevishL.webp';
import bloodsuckerL from './assets/images/bloodsuckerL.webp';
import galvanizadoL from './assets/images/galvanizadoL.webp';
import ironmaskL from './assets/images/ironmaskL.webp';
import purpaulL from './assets/images/purpaulL.webp';
import russL from './assets/images/russL.webp';
import woodyL from './assets/images/woodyL.webp';
import grapeyesL from './assets/images/grapeyesL.webp';
import trampoL from './assets/images/trampoL.webp';
import herberL from './assets/images/herberL.webp';
import mooseL from './assets/images/mooseL.webp';
import johnsonL from './assets/images/johnsonL.webp';

import polyskullM from './assets/images/polyskullM.webp';
import crimsonM from './assets/images/polyskullM.webp'; // <--- placeholder till create
import goldenfaceM from './assets/images/goldenfaceM.webp';
import dimelM from './assets/images/dimelM.webp';
import yellowneckM from './assets/images/yellowneckM.webp';
import silverhandM from './assets/images/silverhandM.webp';
import palidaM from './assets/images/palidaM.webp';
import roseM from './assets/images/roseM.webp';
import peevishM from './assets/images/peevishM.webp';
import bloodsuckerM from './assets/images/bloodsuckerM.webp';
import galvanizadoM from './assets/images/galvanizadoM.webp';
import ironmaskM from './assets/images/ironmaskM.webp';
import purpaulM from './assets/images/purpaulM.webp';
import russM from './assets/images/russM.webp';
import woodyM from './assets/images/woodyM.webp';
import grapeyesM from './assets/images/grapeyesM.webp';
import trampoM from './assets/images/trampoM.webp';
import herberM from './assets/images/herberM.webp';
import mooseM from './assets/images/mooseM.webp';
import johnsonM from './assets/images/johnsonM.webp';

import polyskull from './assets/images/polyskull.webp';
import crimson from './assets/images/crimson.webp';
import goldenface from './assets/images/goldenface.webp';
import dimel from './assets/images/dimel.webp';
import yellowneck from './assets/images/yellowneck.webp';
import silverhand from './assets/images/silverhand.webp';
import palida from './assets/images/palida.webp';
import rose from './assets/images/rose.webp';
import peevish from './assets/images/peevish.webp';
import bloodsucker from './assets/images/bloodsucker.webp';
import galvanizado from './assets/images/galvanizado.webp';
import ironmask from './assets/images/ironmask.webp';
import purpaul from './assets/images/purpaul.webp';
import russ from './assets/images/russ.webp';
import woody from './assets/images/woody.webp';
import grapeyes from './assets/images/grapeyes.webp';
import trampo from './assets/images/trampo.webp';
import herber from './assets/images/herber.webp';
import moose from './assets/images/moose.webp';
import johnson from './assets/images/johnson.webp';
import cracked from './assets/images/cracked.webp';
import ram from './assets/images/ram.webp';
import shadow from './assets/images/shadow.webp';
import kotsu from './assets/images/kotsu.webp';
import beard from './assets/images/beard.webp';

// ** PlaceHolder Images till create image version
import crackedM from './assets/images/goldenfaceM.webp';
import ramM from './assets/images/polyskullM.webp';
import shadowM from './assets/images/trampoM.webp';
import kotsuM from './assets/images/purpaulM.webp';
import beardM from './assets/images/herberM.webp';

import crackedL from './assets/images/goldenfaceL.webp';
import ramL from './assets/images/polyskullL.webp';
import shadowL from './assets/images/trampoL.webp';
import kotsuL from './assets/images/purpaulL.webp';
import beardL from './assets/images/herberL.webp';

import CranniLogo from './assets/images/crannislogo.png';
import loadinggif from './assets/utility/loading.gif';

const HeroNFT = () => {
  const [currentImage, setCurrentImage] = useState(loadinggif);
  const [loading, setLoading] = useState(true);

  const heroImagesL = [
    polyskullL,
    crimsonL,
    goldenfaceL,
    dimelL,
    yellowneckL,
    purpaulL,
    peevishL,
    bloodsuckerL,
    silverhandL,
    palidaL,
    roseL,
    galvanizadoL,
    ironmaskL,
    russL,
    woodyL,
    grapeyesL,
    trampoL,
    herberL,
    mooseL,
    johnsonL,
    crackedL,
    ramL,
    shadowL,
    kotsuL, 
    beardL
  ];

  const heroImagesM = [
    polyskullM,
    crimsonM,
    goldenfaceM,
    dimelM,
    yellowneckM,
    purpaulM,
    peevishM,
    bloodsuckerM,
    silverhandM,
    palidaM,
    roseM,
    galvanizadoM,
    ironmaskM,
    russM,
    woodyM,
    grapeyesM,
    trampoM,
    herberM,
    mooseM,
    johnsonM,
    crackedM,
    ramM,
    shadowM,
    kotsuM, 
    beardM
  ];

  const heroImages = [
    polyskull,
    crimson,
    goldenface,
    dimel,
    yellowneck,
    purpaul,
    peevish,
    bloodsucker,
    silverhand,
    palida,
    rose,
    galvanizado,
    ironmask,
    russ,
    woody,
    grapeyes,
    trampo,
    herber,
    moose,
    johnson,
    cracked,
    ram,
    shadow,
    kotsu, 
    beard
  ];

  const getRandomHeroImage = () => {
    const randomIndex = Math.floor(Math.random() * heroImages.length);
    return {
      lqImage: heroImagesL[randomIndex],
      mqImage: heroImagesM[randomIndex],
      hqImage: heroImages[randomIndex],
    };
  };

  useEffect(() => {
    const loadRandomHeroImage = () => {
      const randomImage = getRandomHeroImage();

      const screenWidth = window.innerWidth;
      if (screenWidth <= 490) {
        const img = new Image();
        img.src = randomImage.lqImage;
        img.onload = () => {
          setCurrentImage(randomImage.lqImage);
          setLoading(false);
        };
      } else if (screenWidth >= 490 && screenWidth <= 768) {
        const img = new Image();
        img.src = randomImage.mqImage;
        img.onload = () => {
          setCurrentImage(randomImage.mqImage);
          setLoading(false);
        };
      } else {
        const img = new Image();
        img.src = randomImage.hqImage;
        img.onload = () => {
          setCurrentImage(randomImage.hqImage);
          setLoading(false);
        };
      }
    };

    loadRandomHeroImage();

    window.addEventListener('resize', loadRandomHeroImage);

    return () => {
      window.removeEventListener('resize', loadRandomHeroImage);
    };
  }, []);

  const backgroundImageStyle = {
    background: `${loading ? `#000000` : ''}`,
    backgroundImage: `url(${currentImage})`,
    maxHeight: `620px`,
    backgroundPosition: 'left'
    //	transition: '0.5s filter linear',
    //	filter: `${loading ? 'blur(1px)' : ''}`
  };

  return (
    <div className='bg-black'>
      <div
        className={`w-full flex bg-no-repeat bg-cover xl:bg-contain pb-5 lg:pb-5 2xl:pb-40`}
        style={backgroundImageStyle}
      >
        <div className="mb-5 sm:px-10 w-full text-white flex-col">
          <div className="ml-5 flex flex-col items-end md:gap-3 mt-10 md:mt-0 p-5 lg:p-0 2xl:p-5">
            <div className="max-w-screen-md flex items-end md:mt-10">
              <div className="flex flex-col items-center mr-5">
                <img
                  src={CranniLogo}
                  alt="Cranni Societas Logo"
                  className="heroblackbackground mb-4"
                />
                <p className="w-full heroblackbackground font-tcbbold text-[18px] sm:text-xl lg:text-2xl mt-10 mb-7 text-left">
                  <h2 className="w-full justify-content-center font-tcbbold text-3xl lg:text-3xl mt-10 mb-7 text-center">
                    Cranni Societas NFTnodes: The Key to Automated Gaming
                  </h2>
                  At Luckblocks, we believe in the power of automation and
                  decentralization to enhance gaming experiences. That's why we
                  have introduced Cranni Societas NFTnodes as the crucial tokens
                  to unlock automated functions within our smart contracts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroNFT;
