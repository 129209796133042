import { useEffect, useState, React } from 'react';
import { ethers } from 'ethers';
import {
  luckBlocksAddress,
  luckBlocksWAddress,
  ethLuckBlocksAddress,
  ethluckBlocksWAddress,
  maticLuckBlocksAddress,
  maticLuckBlocksWAddress,
  CRANNILuckBlocksAddress,
  CRANNILuckBlocksWAddress,
  MultiCall,
  provider,
  wsprovider,
  bckpProvider,
  alchemyBase,
} from '../.config';
import LuckBlocks from '../abi/LuckBlocks.json';
import Multicall from '../abi/Multicall.json';
import CarouselPage from '../Carousel';
import { useAccount } from 'wagmi';
import btc from '../assets/utility/bitcoin.png';
import eth from '../assets/utility/ethereum.png';
import matic from '../assets/utility/matic.png';
import CRANNI from '../assets/utility/CRANNI.png';

const MyTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [ticketsW, setTicketsW] = useState([]);
  const [ticketsP, setTicketsP] = useState([]);
  const [ticketsPW, setTicketsPW] = useState([]);
  const [formInput, updateFormInput] = useState({
    drawId: 0,
  });
  const [formInputW, updateFormInputW] = useState({
    drawId: 0,
  });
  const [currentDraw, updateCurrentDraw] = useState(0);
  const [currentWDraw, updateCurrentWDraw] = useState(0);
  const [lottery, setLottery] = useState('fetch');
  const [ticketType, setTicketType] = useState('Current');
  const { address, isConnected } = useAccount();
  const [loading, setLoading] = useState(true);

  const changeLotteryToBTC = () => {
    setLottery('Bitcoin');
    getCurrentDraw('Bitcoin');
  };
  const changeLotteryToETH = () => {
    setLottery('Ethereum');
    getCurrentDraw('Ethereum');
  };

  const changeLotteryToMATIC = () => {
    setLottery('Matic');
    getCurrentDraw('Matic');
  };
  const changeLotteryToCRANNI = () => {
    setLottery('CRANNI');
    getCurrentDraw('CRANNI');
  };

  let ticketArray = [];
  let ticketArrayW = [];
  let ticketArrayP = [];
  let ticketArrayPW = [];

  const resetTickets = () => {
    ticketArray = [];
    ticketArrayW = [];
    setTickets([]);
    setTicketsW([]);
    setLoading(true);
    setTimeout(() => getAllMyTickets(), 10000);
  };

  async function getCurrentDraw(lottery) {
    let contractAddress;
    let wcontractAddress;

    if (lottery === 'Bitcoin') {
      contractAddress = luckBlocksAddress;
      wcontractAddress = luckBlocksWAddress;
    } else if (lottery === 'Ethereum') {
      contractAddress = ethLuckBlocksAddress;
      wcontractAddress = ethluckBlocksWAddress;
    } else if (lottery === 'Matic') {
      contractAddress = maticLuckBlocksAddress;
      wcontractAddress = maticLuckBlocksWAddress;
    } else if (lottery === 'CRANNI') {
      contractAddress = CRANNILuckBlocksAddress;
      wcontractAddress = CRANNILuckBlocksWAddress;
    }

    const contract = new ethers.Contract(
      contractAddress,
      LuckBlocks.abi,
      wsprovider
    );
    const wcontract = new ethers.Contract(
      wcontractAddress,
      LuckBlocks.abi,
      bckpProvider
    );

    let draw = await contract.requestCounter();
    let wdraw = await wcontract.requestCounter();
    draw = parseInt(draw);
  //  console.log(draw);
    updateCurrentDraw(draw);
    wdraw = parseInt(wdraw);
    updateCurrentWDraw(wdraw);
  }

  async function getPastTicket(lottery, drawId) {
    let contractAddress;

    if (lottery === 'Bitcoin') {
      contractAddress = luckBlocksAddress;
    } else if (lottery === 'Ethereum') {
      contractAddress = ethLuckBlocksAddress;
    } else if (lottery === 'Matic') {
      contractAddress = maticLuckBlocksAddress;
    } else if (lottery === 'CRANNI') {
      contractAddress = CRANNILuckBlocksAddress;
    }

    const contract = new ethers.Contract(MultiCall, Multicall.abi, wsprovider);

    if (isConnected) {
      const ticketInfos = await contract.getPastDrawTickets(
        contractAddress,
        drawId,
        address
      );

      for (const ticket of ticketInfos) {
        const ticketDraw = ticket[0];
        const ticketId = ticket[1];
        const winner = ticket[2];

        ticketArrayP.push({
          weekly: 3,
          lottery: ticketDraw,
          drawId: parseInt(drawId),
          ticketId: parseInt(ticketId),
          winner: winner,
          owner: address,
        });
      }
    }
    setTicketsP(ticketArrayP);
  }

  async function getPastTicketW(lottery, drawId) {
    let contractAddress;

    if (lottery === 'Bitcoin') {
      contractAddress = luckBlocksWAddress;
    } else if (lottery === 'Ethereum') {
      contractAddress = ethluckBlocksWAddress;
    } else if (lottery === 'Matic') {
      contractAddress = maticLuckBlocksWAddress;
    } else if (lottery === 'CRANNI') {
      contractAddress = CRANNILuckBlocksWAddress;
    }

    const contract = new ethers.Contract(MultiCall, Multicall.abi, bckpProvider);

    if (isConnected) {
      const ticketInfos = await contract.getPastDrawTickets(
        contractAddress,
        drawId,
        address
      );

      for (const ticket of ticketInfos) {
        const ticketDraw = ticket[0];
        const ticketId = ticket[1];
        const winner = ticket[2];

        ticketArrayPW.push({
          weekly: 3,
          lottery: ticketDraw,
          drawId: parseInt(drawId),
          ticketId: parseInt(ticketId),
          winner: winner,
          owner: address,
        });
      }
    }
    setTicketsPW(ticketArrayPW);
  }

  async function getAllMyTickets() {
    const contract = new ethers.Contract(
      MultiCall,
      Multicall.abi,
      bckpProvider
    ); // for alchemy >> await alchemyBase.config.getProvider()
    const bckpContract = new ethers.Contract(
      MultiCall,
      Multicall.abi,
      provider
    );


    if (isConnected) {
      const lotteryInfos = await bckpContract.getLotteryInfos();
      const lotteryInfosW = await bckpContract.getLotteryInfosW();
      const tickets = await contract.getAllMyTickets(address);
      const ticketsW = await contract.getAllMyTicketsW(address);

      for (const ticket of tickets) {
        const ticketDraw = ticket[0];
        const ticketId = ticket[1];
        let drawId;
        let jackpotWinC;
        let jackpotWinU;
        let getNextSpin;

        if (ticketDraw === 'Bitcoin') {
          drawId = lotteryInfos[0][1];
          jackpotWinC = lotteryInfos[0][3];
          jackpotWinU = lotteryInfos[0][4];
          getNextSpin = lotteryInfos[0][2] * 1000 + 86400000;
        } else if (ticketDraw === 'Ethereum') {
          drawId = lotteryInfos[1][1];
          jackpotWinC = lotteryInfos[1][3];
          jackpotWinU = lotteryInfos[1][4];
          getNextSpin = lotteryInfos[1][2] * 1000 + 86400000;
        } else if (ticketDraw === 'Matic') {
          drawId = lotteryInfos[2][1];
          jackpotWinC = lotteryInfos[2][3];
          jackpotWinU = lotteryInfos[2][4];
          getNextSpin = lotteryInfos[2][2] * 1000 + 86400000;
        } else {
          drawId = lotteryInfos[3][1];
          jackpotWinC = lotteryInfos[3][3];
          jackpotWinU = lotteryInfos[3][4];
          getNextSpin = lotteryInfos[3][2] * 1000 + 86400000;
        }

        const formattedJpWei = ethers.utils.formatUnits(
          jackpotWinC.toString(),
          ticketDraw === 'Bitcoin' || ticketDraw === 'CRANNI' ? 8 : 'ether'
        );
        const formattedJpUsd = ethers.utils.formatUnits(
          jackpotWinU.toString(),
          'ether'
        );

        ticketArray.push({
          weekly: 0,
          lottery: ticketDraw,
          drawId: parseInt(drawId),
          ticketId: parseInt(ticketId),
          nextSpin: parseInt(getNextSpin),
          jackpotWinC:
            ticketDraw === 'CRANNI' || ticketDraw === 'Matic'
              ? parseFloat(formattedJpWei).toFixed(3)
              : parseFloat(formattedJpWei).toFixed(4),
          jackpotWinU: parseFloat(formattedJpUsd).toFixed(2),
          owner: address,
        });
      }

      for (const ticketW of ticketsW) {
        const ticketDraw = ticketW[0];
        const ticketId = ticketW[1];
        let drawId;
        let jackpotWinC;
        let jackpotWinU;
        let getNextSpin;

        if (ticketDraw === 'Bitcoin') {
          drawId = lotteryInfosW[0][1];
          jackpotWinC = lotteryInfosW[0][3];
          jackpotWinU = lotteryInfosW[0][4];
          getNextSpin = lotteryInfosW[0][2] * 1000 + 604800000;
        } else if (ticketDraw === 'Ethereum') {
          drawId = lotteryInfosW[1][1];
          jackpotWinC = lotteryInfosW[1][3];
          jackpotWinU = lotteryInfosW[1][4];
          getNextSpin = lotteryInfosW[1][2] * 1000 + 604800000;
        } else if (ticketDraw === 'Matic') {
          drawId = lotteryInfosW[2][1];
          jackpotWinC = lotteryInfosW[2][3];
          jackpotWinU = lotteryInfosW[2][4];
          getNextSpin = lotteryInfosW[2][2] * 1000 + 604800000;
        } else {
          drawId = lotteryInfos[3][1];
          jackpotWinC = lotteryInfosW[3][3];
          jackpotWinU = lotteryInfosW[3][4];
          getNextSpin = lotteryInfosW[3][2] * 1000 + 604800000;
        }

        const formattedJpWei = ethers.utils.formatUnits(
          jackpotWinC.toString(),
          ticketDraw === 'Bitcoin' || ticketDraw === 'CRANNI' ? 8 : 'ether'
        );
        const formattedJpUsd = ethers.utils.formatUnits(
          jackpotWinU.toString(),
          'ether'
        );

        ticketArrayW.push({
          weekly: 1,
          lottery: ticketDraw,
          drawId: parseInt(drawId),
          ticketId: parseInt(ticketId),
          nextSpin: parseInt(getNextSpin),
          jackpotWinC:
            ticketDraw === 'CRANNI' || ticketDraw === 'Matic'
              ? parseFloat(formattedJpWei).toFixed(3)
              : parseFloat(formattedJpWei).toFixed(4),
          jackpotWinU: parseFloat(formattedJpUsd).toFixed(2),
          owner: address,
        });
      }
    }

    setTickets(ticketArray);
    setTicketsW(ticketArrayW);
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Set loading to false after a brief delay
    }, 3000);
  }, [tickets, ticketsW, ticketsP, ticketsPW]);

  useEffect(() => {
    if (ticketType === 'Current') {
      setTicketsP([]);
      setTicketsPW([]);
      setLoading(true);
      getAllMyTickets();
    } else if (ticketType === 'Past') {
      setTickets([]);
      setTicketsW([]);
      setLoading(true);
      getPastTicket(lottery, 0);
      getPastTicketW(lottery, 0);
    }
  }, [ticketType]);

  useEffect(() => {
    if (lottery == 'fetch') {
      changeLotteryToBTC();
      getPastTicket(lottery, 0);
      getPastTicketW(lottery, 0);
    }
  }, []);

  useEffect(() => {
    resetTickets();
  }, [address, isConnected]);

  useEffect(() => {
    setLoading(true);
    const { drawId } = formInput;
    setTicketsP([]);
    getPastTicket(lottery, drawId);
  }, [formInput, lottery]);

  useEffect(() => {
    setLoading(true);
    const { drawId } = formInputW;
    setTicketsPW([]);
    getPastTicketW(lottery, drawId);
  }, [formInputW, lottery]);

  return (
    <div className="w-full flex justify-center bg-gradient-to-b via-[#202e44] from-black to-[#596b87]  ">
      <div className="playcard max-w-screen-lg w-full rounded-[29px]">
        <div className="m-5 p-5 flex flex-wrap items-center">
          <button
            onClick={() => setTicketType('Current')}
            className={`${
              ticketType === 'Current'
                ? 'bg-amber-400 text-black'
                : 'bg-black text-amber-400'
            }  mr-2 w-[100px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-md font-tcbregular italic`}
          >
            Current Draws
          </button>
          <button
            onClick={() => setTicketType('Past')}
            className={`${
              ticketType === 'Past'
                ? 'bg-amber-400 text-black'
                : 'bg-black text-amber-400'
            } w-[100px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-md font-tcbregular italic`}
          >
            Past Draws
          </button>
        </div>

        {ticketType == 'Current' ? (
          <>
            <div className="w-full">
              <h2 className="text-[25px] md:text-4xl text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                MY DAILY DRAW TICKETS
              </h2>
              {/*
            <CarouselPage tickets={tickets}
                lottery={lottery}
                nextSpin={nextSpin}
                jackpotWinC={fourDecimalNumber(currentPriceWei)}
                jackpotWinU={fourDecimalNumber(jackpotUsd)}
                weekly={0} />
        */}
              {loading ? (
                // Render the loading spinner or any other loading indicator
                <div className="text-center">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline w-[150px] h-[150px] mr-2 text-transparent animate-spin fill-purple-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : tickets.length > 0 ? (
                <CarouselPage tickets={tickets} resetTickets={resetTickets} />
              ) : (
                <>
                  <div className="h-auto ">
                    <div className="w-full flex-col justify-center p-10 bg-transparent">
                      <h1 className="font-bold text-center text-white items-center justify-center text-sm font-tcbregular">
                        You don't Have Tickets on this Draw.
                      </h1>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="w-full">
              <h2 className="text-[25px] md:text-4xl text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
                MY WEEKLY DRAW TICKETS
              </h2>
              {loading ? (
                // Render the loading spinner or any other loading indicator
                <div className="text-center">
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="inline w-[150px] h-[150px] mr-2 mb-2 text-transparent animate-spin fill-purple-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : ticketsW.length > 0 ? (
                <CarouselPage tickets={ticketsW} resetTickets={resetTickets} />
              ) : (
                <>
                  <div className="h-auto ">
                    <div className="w-full flex-col justify-center p-10 bg-transparent">
                      <h1 className="font-bold text-center text-white items-center justify-center text-sm font-tcbregular">
                        You don't Have Tickets on this Draw.
                      </h1>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="m-5 p-5 flex flex-wrap items-center">
              <button
                onClick={changeLotteryToBTC}
                className={`${
                  lottery === 'Bitcoin'
                    ? 'bg-amber-400 text-black'
                    : 'bg-black text-amber-400'
                } mr-2 w-[50px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-xs font-tcbregular italic`}
              >
                <img src={btc} height="35px" width="35px"></img>
              </button>
              <button
                onClick={changeLotteryToETH}
                className={`${
                  lottery === 'Ethereum'
                    ? 'bg-amber-400 text-black'
                    : 'bg-black text-amber-400'
                } mr-2 w-[50px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-xs font-tcbregular italic`}
              >
                <img src={eth} height="35px" width="35px"></img>
              </button>
              <button
                onClick={changeLotteryToMATIC}
                className={`${
                  lottery === 'Matic'
                    ? 'bg-amber-400 text-black'
                    : 'bg-black text-amber-400'
                } mr-2 w-[50px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-xs font-tcbregular italic`}
              >
                <img src={matic} height="35px" width="35px"></img>
              </button>
              <button
                onClick={changeLotteryToCRANNI}
                className={`${
                  lottery === 'CRANNI'
                    ? 'bg-amber-400 text-black'
                    : 'bg-black text-amber-400'
                } w-[50px] h-auto mt-2 mb-2 flex font-bold text-center items-center justify-center rounded-[10px] border-2 border-amber-400 text-xs font-tcbregular italic`}
              >
                <img src={CRANNI} height="35px" width="35px"></img>
              </button>
            </div>
            <div className="text-[25px] md:text-4xl text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
              Daily
            </div>
            <div className="p-5 m-5 w-1/2">
              <input
                type="number"
                min={0}
                max={currentDraw}
                id="draw"
                value={formInput.drawId}
                onInput={(e) => {
                  const inputValue = e.target.value;
                  const integerValue = parseInt(inputValue);

                  if (!isNaN(integerValue)) {
                    updateFormInput({ ...formInput, drawId: integerValue });
                  } else {
                    updateFormInput({ ...formInput, drawId: '' });
                  }
                }}
                className="block w-[100px] bg-black border text-center text-gray-300 text-center text-[20px] h-10 border-[#B2FAFF] rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <p className="mt-2 text-sm  text-[#8E90FF]">Draw ID</p>{' '}
              <p className="mt-2 text-md text-[#8E90FF]">
                Current Draw: <span className="text-white">{currentDraw}</span>
              </p>
            </div>
            {loading ? (
              // Render the loading spinner or any other loading indicator
              <div className="text-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-[150px] h-[150px] mr-2 mb-2 text-transparent animate-spin fill-purple-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : ticketsP.length > 0 ? (
              <CarouselPage tickets={ticketsP} resetTickets={resetTickets} />
            ) : (
              <>
                <div className="h-auto ">
                  <div className="w-full flex-col justify-center p-10 bg-transparent">
                    <h1 className="font-bold text-center text-white items-center justify-center text-sm font-tcbregular">
                      You didn't Have Tickets on this Draw.
                    </h1>
                  </div>
                </div>
              </>
            )}
            <div className="text-[25px] md:text-4xl text-center mt-5 text-white font-bold font-tcbbold italic mt-10 mb-7">
              Weekly
            </div>
            <div className="p-5 m-5 w-1/2">
              <input
                type="number"
                min={0}
                max={currentWDraw}
                id="draw"
                value={formInputW.drawId}
                onInput={(e) => {
                  const inputValue = e.target.value;
                  const integerValue = parseInt(inputValue);

                  if (!isNaN(integerValue)) {
                    updateFormInputW({ ...formInputW, drawId: integerValue });
                  } else {
                    updateFormInputW({ ...formInputW, drawId: '' });
                  }
                }}
                className="block w-[100px] bg-black border text-center text-gray-300 text-center text-[20px] h-10 border-[#B2FAFF] rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <p className="mt-2 text-sm  text-[#8E90FF]">Draw ID</p>{' '}
              <p className="mt-2 text-md text-[#8E90FF]">
                Current Draw: <span className="text-white">{currentWDraw}</span>
              </p>
            </div>
            {loading ? (
              // Render the loading spinner or any other loading indicator
              <div className="text-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-[150px] h-[150px] mr-2 mb-2 text-transparent animate-spin fill-purple-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : ticketsPW.length > 0 ? (
              <CarouselPage tickets={ticketsPW} resetTickets={resetTickets} />
            ) : (
              <>
                <div className="h-auto ">
                  <div className="w-full flex-col justify-center p-10 bg-transparent">
                    <h1 className="font-bold text-center text-white items-center justify-center text-sm font-tcbregular">
                      You didn't Have Tickets on this Draw.
                    </h1>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MyTickets;
