import React, { useEffect } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import btc from "./assets/utility/bitcoin.png"
import eth from "./assets/utility/ethereum.png"
import matic from "./assets/utility/matic.png"
import CRANNI from "./assets/utility/CRANNI.png"

const Main = () => {
	const location = useLocation();
	const [selectedCrypto, setSelectedCrypto] = React.useState(null);
	const { pathname } = location;
	const splitLocation = pathname.split("/");

	useEffect(() => {
		setSelectedCrypto(splitLocation[1]);
	}, [splitLocation]);

	return (
		<section className="w-full flex justify-center bg-black"> {/* Previous #010b18 */}
			<div className="overflow-x-hidden max-w-screen-lg w-full flex justify-center">
				<div className="flex flex-col items-center w-full">
					<nav className="lg:ml-14 bg-black max-w-screen-xl w-full flex justify-center">
						<ul className="flex gap-1 font-bold text-white">
							<Link to="/" className="link">
								<li
									className={
										splitLocation[1] === ""
											? "notranslate bg-black hover:border-dotted border-2 border-blue-400 border-b-transparent border-b-none cursor-pointer rounded-t-xl h-full p-2 w-14 sm:w-28 text-[12px] flex items-center justify-center"
											: "notranslate cursor-pointer bg-slate-700 hover:border-2 hover:border-dotted hover:bg-black rounded-t-xl h-full p-2 w-14 sm:w-28 text-[12px] flex items-center justify-center"
									}
								>
									{selectedCrypto === "" && (
										<img src={btc} alt="BTC" height="15px" width="15px" className="relative mr-1" />
									)}
									BTC
								</li>
							</Link>

							<Link to="/ethereum" className="link">
								<li 
									className={
										splitLocation[1] === "ethereum"
											? "notranslate bg-black hover:border-dotted border-2 border-blue-400 border-b-transparent cursor-pointer rounded-t-xl h-full p-2 w-14 sm:w-28 text-[12px] flex items-center justify-center"
											: "notranslate cursor-pointer bg-slate-700 hover:border-2 hover:border-dotted hover:bg-black rounded-t-xl h-full p-2 w-14 sm:w-28 text-[12px] flex items-center justify-center"
									}
								>
									{selectedCrypto === "ethereum" && (
										<img src={eth} alt="ETH" height="15px" width="15px" className="relative mr-1" />
									)}
									ETH
								</li>
							</Link>
							<Link to="/matic" className="link">
								<li 
									className={
										splitLocation[1] === "matic"
											? "notranslate bg-black hover:border-dotted border-2 border-blue-400 border-b-transparent cursor-pointer rounded-t-xl h-full p-2 w-14 sm:w-28 text-[10px] sm:text-[12px] flex items-center justify-center"
											: "notranslate cursor-pointer bg-slate-700 hover:border-2 hover:border-dotted hover:bg-black rounded-t-xl h-full p-2 w-14 sm:w-28 text-[12px] flex items-center justify-center"
									}
								>
									{selectedCrypto === "matic" && (
										<img src={matic} alt="MATIC" height="15px" width="15px" className="relative mr-1" />
									)}
									POL
								</li>
							</Link>
							<Link to="/CRANNI" className="link">
								<li 
									className={
										splitLocation[1] === "CRANNI"
											? "notranslate bg-black hover:border-dotted border-2 border-blue-400 border-b-transparent cursor-pointer rounded-t-xl h-full p-2 w-14 sm:w-28 text-[7px] sm:text-[12px] flex items-center justify-center"
											: "notranslate cursor-pointer bg-slate-700 hover:border-2 hover:border-dotted hover:bg-black rounded-t-xl h-full p-2 w-14 sm:w-28 text-[12px] flex items-center justify-center"
									}
								>
									{selectedCrypto === "CRANNI" && (
										<img src={CRANNI} alt="CRANNI" height="15px" width="15px" className="relative mr-1" />
									)}
									CRANNI
								</li>
							</Link>
							<Link to="/my-tickets" className="link">
								<li
									className={
										splitLocation[1] === "my-tickets"
											? "bg-black  hover:border-dotted border-2 border-blue-400 border-b-transparent cursor-pointer rounded-t-xl h-full p-2 w-14 sm:w-28 text-[12px] flex items-center justify-center"
											: "cursor-pointer bg-slate-700 hover:border-2 hover:border-dotted hover:bg-black rounded-t-xl h-full p-2 w-14 sm:w-28 text-[12px] flex items-center justify-center"
									}
								>
									MY TICKETS
								</li>
							</Link>
						</ul>
					</nav>
					<Outlet />
				</div>
			</div>
		</section>
	);
};

export default Main;
