import React, { useState, useEffect, Suspense, lazy } from 'react';
import link from '../assets/Icons/biglink.png';
import winningNumber from '../assets/utility/TicketK.webp';
import info from '../assets/utility/info.svg';
import { ethers } from 'ethers';
import {
  CRANNILuckBlocksAddress,
  CRANNILuckBlocksWAddress,
  MultiCall,
  wsprovider,
  provider,
  bckpProvider,
} from '../.config';
import CRANNILuckBlocks from '../abi/CRANNILuckblocks.json';
import cranniLogo from '../assets/utility/CRANNI.png';
import metamaskLogo from '../assets/utility/metamask.webp';
import Multicall from '../abi/Multicall.json';
import Tooltip from '../components/UI/Tooltip';
import { threeDecimalNumber } from '../utils';
import CountdownTimer from '../components/Countdown/CountdownTimer';
import { useAccount, useWalletClient } from 'wagmi';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { shortAddress } from '../utils';

const Banner = lazy(() => import('../Banner'));
const Logs = lazy(() => import('../Logs'));

let loadOneLogTimeout = null;

const CRANNI = ({ setShowDraw }) => {
  const [loading, setLoading] = useState(true);
  const [ddrawid, setDDrawId] = useState(0);
  const [wdrawid, setWDrawId] = useState(0);
  const [winner, setWinner] = useState('');
  const [weeklyWinner, setweeklyWinner] = useState('');
  const [winnerTicket, setWinnerTicket] = useState('');
  const [weeklyWinnerTicket, setweeklyWinnerTicket] = useState('');
  const [winnerURL, setWinnerURL] = useState('');
  const [weeklyWinnerURL, setweeklyWinnerURL] = useState('');
  const [nextSpin, setNextSpin] = useState('');
  const [nextSpinWeekly, setNextSpinWeekly] = useState('');
  const [ticketBought, setTicketBought] = useState(0);
  const [ticketWeekly, setticketWeekly] = useState(0);
  const [dailyChance, setWinningChance] = useState(0);
  const [weeklyChance, setWinningChanceW] = useState(0);
  const [currentPriceWei, setCurrentPriceWei] = useState(0);
  const [jackpotUsd, setJackpotUsd] = useState(0);
  const [currentPriceWeiW, setCurrentPriceWeiW] = useState(0);
  const [jackpotUsdW, setJackpotUsdW] = useState(0);
  const [txs, setTxs] = useState([]);
  const { address, isConnected } = useAccount();
  const { data: walletClient } = useWalletClient();
  const [coinPrice, setCoinPrice] = useState(0);
  const [mkCap, setmkCap] = useState(0);

  const addTokenToMetaMask = async () => {
    if (!isConnected) {
      Swal.fire({
        title: 'Warning',
        text: 'Provider is not Available!',
        icon: 'info',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    try {

      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: '0x8C426Bc4506b4f7BfB21cF67A342e0E7EBe0980f', // '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6'
            symbol: 'CRANNI', // Replace with your token's symbol
            decimals: 8, // Replace with your token's decimals
            image: '../assets/utility/CRANNI.png', // Replace with your token's logo URL
          },
        },
      });

      if (wasAdded) {
        Swal.fire({
          title: 'Success',
          text: 'Token Was added to Wallet List',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          title: 'Warning',
          text: 'Token Was not added to List',
          icon: 'warning',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: `An Error was ocurred : ${error}`,
        icon: 'error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const contract = new ethers.Contract(
    CRANNILuckBlocksAddress,
    CRANNILuckBlocks.abi,
    wsprovider
  );

  const bcontract = new ethers.Contract(
    CRANNILuckBlocksAddress,
    CRANNILuckBlocks.abi,
    bckpProvider
  );

  // Weekly Lottery Contract
  const Wcontract = new ethers.Contract(
    CRANNILuckBlocksWAddress,
    CRANNILuckBlocks.abi,
    bckpProvider
  );

  const httpcontract = new ethers.Contract(
    CRANNILuckBlocksAddress,
    CRANNILuckBlocks.abi,
    provider
  );

  const multicontract = new ethers.Contract(
    MultiCall,
    Multicall.abi,
    bckpProvider
  );
  async function getRaffleWinner() {
    const lotteryInfos = await multicontract.getLotteryInfos();
    const lotteryInfosW = await multicontract.getLotteryInfosW();
    let drawId = lotteryInfos[3][1];
    let weeklyDrawId = lotteryInfosW[3][1];
    let lastWinnerTicket = 0;
    let lastWeeklyWinnerTicket = 0;

    if (drawId > 0) {
      let pastDrawId = drawId - 1;
      let getlastWinnerTicket = await contract.jackpotWinHistory(pastDrawId);
      lastWinnerTicket = getlastWinnerTicket.id.toString();
    }
    if (weeklyDrawId > 0) {
      let pastWeeklyDrawId = weeklyDrawId - 1;
      let getlastWeeklyWinnerTicket = await Wcontract.jackpotWinHistory(
        pastWeeklyDrawId
      );
      lastWeeklyWinnerTicket = getlastWeeklyWinnerTicket.id.toString();
    }

    const getNextSpin = lotteryInfos[3][2] * 1000 + 86400000;
    const getNextSpinWeekly = lotteryInfosW[3][2] * 1000 + 604800000;

    const totalTickets = await contract.amountOfRegisters();
    const lastWinner = await contract.ourLastWinner();
    const lastWeeklyWinner = await Wcontract.ourLastWinner();
    const wTotalTickets = await Wcontract.amountOfRegisters();

    const jpWei = lotteryInfos[3][3];
    const jpUsd = lotteryInfos[3][4];
    const jpWeiW = lotteryInfosW[3][3];
    const jpUsdW = lotteryInfosW[3][4];

    const formatedTotalTicket =
      ((await ethers.utils.formatUnits(totalTickets.toString(), 'ether')) * 1e18).toFixed(0);
    const wformatedTotalTicket =
      ((await ethers.utils.formatUnits(wTotalTickets.toString(), 'ether')) *
      1e18).toFixed(0);
    const formatedJpWei = await ethers.utils.formatUnits(
      jpWei.toString(),
      8
    );
    const formatedJpUsd = await ethers.utils.formatUnits(
      jpUsd.toString(),
      'ether'
    );
    const formatedJpWeiW = await ethers.utils.formatUnits(
      jpWeiW.toString(),
      8
    );
    const formatedJpUsdW = await ethers.utils.formatUnits(
      jpUsdW.toString(),
      'ether'
    );

    const formatedWinningChance =
      ((1 / formatedTotalTicket) * 100).toFixed(4) + ' %';
    const formatedWinningChanceW =
      ((1 / wformatedTotalTicket) * 100).toFixed(4) + ' %';

    const winnerURLset = 'https://polygonscan.com/address/' + lastWinner;
    const weeklywinnerURLset =
      'https://polygonscan.com/address/' + lastWeeklyWinner;

    setDDrawId(drawId.toString());
    setWDrawId(weeklyDrawId.toString());

    setCurrentPriceWei(parseFloat(formatedJpWei));

    setJackpotUsd(parseFloat(formatedJpUsd));

    setCurrentPriceWeiW(parseFloat(formatedJpWeiW));

    setJackpotUsdW(parseFloat(formatedJpUsdW));

    setLoading(false);

    setTicketBought(formatedTotalTicket);
    setticketWeekly(wformatedTotalTicket);

    setWinnerTicket(lastWinnerTicket);
    setweeklyWinnerTicket(lastWeeklyWinnerTicket);

    setNextSpin(parseInt(getNextSpin));
    setNextSpinWeekly(parseInt(getNextSpinWeekly));

    setWinningChance(formatedWinningChance);
    setWinningChanceW(formatedWinningChanceW);

    setWinner(lastWinner);
    setWinnerURL(winnerURLset);

    setweeklyWinner(lastWeeklyWinner);
    setweeklyWinnerURL(weeklywinnerURLset);
  }

  async function loadLogs() {
    const currentBlock = await wsprovider.getBlockNumber();
    const startBlock = currentBlock - 45000;  // 83200;
    let eventFilter = bcontract.filters.LotteryLog();
    const items = await bcontract.queryFilter(
      eventFilter,
      startBlock,
      currentBlock
    );
    /*
    let weventFilter = Whttpcontract.filters.LotteryLog();
    const Witems = await Whttpcontract.queryFilter(weventFilter, startBlock, currentBlock);

    const filteredItems = Witems.filter(function (element) {
      return (
        element.args[2] ===
        "We have a new Weekly Raffle Winner"
      );
    });
    // setTxs(items);
    // console.log("filteredItems", filteredItems);
    if (filteredItems.length > 0) {
      items.push(filteredItems);
    }
    */
    //	console.log(items);
    setTxs(items);
  }

  async function loadOneLog() {
    let eventFilter = httpcontract.filters.LotteryLog();
    const currentBlock = await provider.getBlockNumber();
    const item = await httpcontract.queryFilter(
      eventFilter,
      currentBlock - 1,
      currentBlock
    );

    if (item.length > 0) {
      setTxs((prevTxs) => [...prevTxs, ...item]);
    }
  }

  async function loadPrice() {
    let coinPrice = await httpcontract.getLatestPrice() / 100000000;
    let mkcap = (coinPrice * 888000).toFixed(2);

    setCoinPrice((coinPrice).toFixed(4).toString());
    setmkCap(mkcap.toString());

  }

  function loadDeferredIframe() {
    var iframe = document.getElementById('boggedchart');
    iframe.src =
      'https://teams.bogged.finance/embeds/chart?address=0x671078C0496Fa135a8c45fC7c9FA7B1501fD5146&chain=matic&charttype=line&theme=bg:00102AFF|bg2:00102AFF|primary:9F14F5FF|secondary:941BC8FF|text:F3F6FBFF|text2:F3F6FBFF|candlesUp:1BC870FF|candlesDown:ff4976ff|chartLine:A908DAFF&defaultinterval=15m&showchartbutton=false';
  }

  useEffect(() => {
    getRaffleWinner();
    loadLogs();
    loadPrice();
    //loadDeferredIframe();
  }, []);

  useEffect(() => {
    contract.on('LotteryLog', handleLotteryLogEvent);

    return () => {
      // Cleanup: Unsubscribe the event handler when the component unmounts
      contract.off('LotteryLog', handleLotteryLogEvent);
    };
  }, []); // Empty dependency array to run the effect only once

  // Define the event handler functions
  function handleLotteryLogEvent() {
    getRaffleWinner();
    if (!loadOneLogTimeout) {
      // Run loadOneLog after a delay if no timeout is already set
      loadOneLogTimeout = setTimeout(() => {
        loadOneLog();
        loadOneLogTimeout = null;
      }, 2000);
    }
  }

  return (
    <>
      <div className="w-full flex justify-center bg-gradient-to-b via-[#202e44] from-black to-[#596b87]  ">
        <div className="playcard max-w-screen-lg w-full rounded-[29px]">
          <div className="backgroundcard max-w-screen-lg w-full flex flex-col items-center p-10 text-white rounded-[29px]">
            {loading ? (
              <div className="text-center">
                <div role="status">
                  <svg
                    aria-hidden="true"
                    class="inline w-[50px] h-[50px] mr-2 text-transparent animate-spin fill-purple-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <></>
            )}
            <h1 className="text-[30px] font-bold italic text-center font-tcbbold mb-5">
              CURRENT JACKPOTS
            </h1>
            <h1 className="text-xl font-bold italic text-center font-tcbbold mb-5">
              Daily Draw <span className="notranslate">#{ddrawid}</span>
            </h1>
            <div className="notranslate flex gap-2 lg:gap-3 mb-20 border-2 border-blue-400 font-tcbbold rounded-[40px] py-5 px-6 text-4xl">
              <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                {threeDecimalNumber(currentPriceWei)} CRANNI{' '}
              </span>
              <span className="text-3xl">~</span> {jackpotUsd.toFixed(2)} USD
            </div>
            <h1 className="text-xl font-bold italic text-center font-tcbbold mb-5">
               Weekly Draw <span className="notranslate">#{wdrawid}</span>
            </h1>
            <div className="notranslate flex gap-2 lg:gap-3 mb-20 border-2 border-blue-400 font-tcbbold rounded-[40px] py-5 px-6 text-4xl">
              <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#13EBFD] to-[#F807E7] padding-box">
                {threeDecimalNumber(currentPriceWeiW)} CRANNI{' '}
              </span>
              <span className="text-3xl">~</span> {jackpotUsdW.toFixed(2)} USD
            </div>

            <h2 className="text-[30px] font-bold italic font-tcbbold mb-5">
              LATEST WINNERS
            </h2>

            {/* {ball winning} */}
            <div className="w-full flex lg:h-[192px] h-[360px} bg-gradient-to-r from-gray-600 to-gray-600 via-purple-900  p-5 rounded-[34px]">
            <div className="w-full flex lg:flex-row flex-col self-center">
                <div className="flex flex-col items-center mb-5 lg:border-r lg:border-white px-10">
                  <h2 className="text-x2 font-bold text-center font-tcbbold">
                    Latest Daily Raffle Winner
                  </h2>

                  <a href={winnerURL} className="mt-5">
                    <button className="notranslate flex items-center gap-2 bg-[#323965] h-[30px] w-[260px] px-4 py-5 rounded-[15px] text-sm mb-5">
                      {shortAddress(winner)}
                      <img src={link} alt="link" className="h-[15px]" />
                    </button>
                  </a>

                  <h2 className="text-x2 font-bold text-center font-tcbbold">
                    Next Spin in
                  </h2>
                  <CountdownTimer targetDate={nextSpin} />
                </div>

                <div className="flex flex-col items-center mb-5 lg:border-r lg:border-white px-10">
                  <h2 className="text-x2 font-bold text-center font-tcbbold">
                    Latest Weekly Raffle Winner
                  </h2>

                  <a href={weeklyWinnerURL} className="mt-5">
                    <button className="notranslate flex items-center gap-2 bg-[#323965] h-[30px] w-[260px] px-4 py-5 rounded-[15px] text-sm mb-5">
                      {shortAddress(weeklyWinner)}
                      <img src={link} alt="link" className="h-[15px]" />
                    </button>
                  </a>
                  <h2 className="text-x2 font-bold text-center font-tcbbold">
                    Next Spin in
                  </h2>
                  <CountdownTimer targetDate={nextSpinWeekly} />
                </div>
              </div>

              <h3 className="rotate-[270deg] font-bold hidden lg:flex text-lg w-[100%] flex items-center justify-center p-2">
                Ticket #: {weeklyWinnerTicket}
              </h3>
              <div className="w-[146px] hidden lg:flex  absolute translate-y-[-23%] translate-x-[550%]">
                <img src={winningNumber} alt="winning_number" className="" />
                <p className="font-tcbbold text-center absolute translate-y-[110%] translate-x-[45%]">
                  Ticket #{weeklyWinnerTicket}
                </p>
              </div>
            </div>

            <div className="border border-1 rounded-[28px] p-[30px] pb-10 mt-5">
              <div className="flex flex-col sm:flex-col justify-between gap-2 w-full mt-16 ">
                {/*
								<h2 className="text-4xl font-bold text-center italick font-tcbnormal mb-5">
									MY TICKETS
								</h2>
								{/* Tickets Part */}

                {/* Tickets Part */}

                <h2 className="text-[50px] font-bold text-center italic font-tcbbold mb-5">
                  INFO
                </h2>
                <div className="bg-purple-700 rounded-xl flex items-center justify-center p-1">
                  <div className="flex flex-col items-center gap-1 ml-0 mt-2 mb-2">
                    <img src={cranniLogo} alt="logo" className="w-16 h-16" /> {/* Replace with the actual logo */}
                    <p className="notranslate text-xl text-white">CRANNI</p> {/* Replace with the actual name */}
                    <a href={"https://docs.luckblocks.com/token"} className="notranslate text-blue-500 underline bg-black rounded-xl text-white p-2" target="_blank" rel="noopener noreferrer">
                      https://luckblocks.com
                    </a>
                  </div>

                  <div className="flex flex-col items-end gap-2 ml-5">
                  <p className="notranslate text-2xl font-bold text-white bg-purple-900 rounded-xl p-1">${coinPrice}</p> {/* Get from smart contract */}
                   {/* <p className="text-xl text-white bg-purple-900 rounded-xl p-1">~${mkCap} {/* totalsupply * price */}
                    <button
                      onClick={addTokenToMetaMask}
                      className="flex items-center justify-center mt-2 p-2 bg-orange-500 rounded-lg text-white"
                    >
                      <img src={metamaskLogo} alt="MetaMask Logo" className="w-6 h-6 mr-2" />
                      Add to Wallet
                    </button>
                  </div>
                </div>
              </div>

            <div className="flex flex-col sm:flex-row  mt-3 gap-4">
              <ul className="flex flex-col gap-2">
                <li className="flex font-bold border-b border-[#8E90FF]  text-xl text-[#8E90FF] flex-col justify-center items-start py-7 px-5 w-80">
                  CURRENT TICKETS IN DAILY:{' '}
                  <Tooltip
                    message={
                      'This daily raffle will draw with minimum 10 tickets and 200 USDT jackpot value'
                    }
                  >
                     <p className="notranslate font-bold text-xl flex items-center gap-3 text-white">
                        {ticketBought} / 100{' '}
                        <img src={info} alt="info" className="w-4 h-4" />{' '}
                      </p>
                  </Tooltip>
                </li>
                <li className="flex font-bold border-b border-[#8E90FF]  text-xl text-[#8E90FF] flex-col justify-center items-start py-7 px-5 w-80">
                  CURRENT TICKETS IN WEEKLY:{' '}
                  <Tooltip
                    message={
                      'This weekly raffle will draw with minimum 200 USDT jackpot value'
                    }
                  >
                      <p className="notranslate font-bold text-xl flex items-center gap-3 text-white">
                        {ticketWeekly} / 700{' '}
                        <img src={info} alt="info" className="w-4 h-4" />{' '}
                      </p>
                  </Tooltip>
                </li>
                <li className="flex font-bold border-b border-[#8E90FF]  text-xl text-[#8E90FF] flex-col justify-center items-start py-7 px-5 w-80">
                  PRICE PER TICKET{' '}
                  <Tooltip
                    message={
                      ' Price Per Ticket, holders of over 888 CRANNIs get 20% discount, buy over 10 tickets to get more 5% discount. Community can vote to change this price.'
                    }
                  >
                    <p className="notranslate font-bold text-xl flex items-center gap-3 text-white">
                      15 USDT{' '}
                      <img src={info} alt="info" className="w-4 h-4" />
                    </p>
                  </Tooltip>
                </li>
                <li className="flex font-bold  text-xl text-[#8E90FF] flex-col justify-center items-start py-7 px-5 w-80">
                  CONTRACT TICKETS{' '}
                  <Tooltip
                    message={
                      ' Pre-Bought tickets from contract, wherever it wins accumulates Jackpot.'
                    }
                  >
                    <p className="font-bold text-xl flex items-center gap-3 text-white">
                      2 <img src={info} alt="info" className="w-4 h-4" />
                    </p>
                  </Tooltip>
                </li>
              </ul>

              <ul className="flex flex-col gap-2">
                <li className="flex font-bold border-b border-[#8E90FF]  text-xl text-[#8E90FF] flex-col justify-center items-start py-7 px-5 w-80">
                  LAST WINNER TICKET #:{' '}
                  <Tooltip
                    message={
                      ' Ticket number from the last daily raffle winner.'
                    }
                  >
                    <p className="font-bold text-xl flex items-center gap-3 text-white">
                      {`${winnerTicket}`}{' '}
                      <img src={info} alt="info" className="w-4 h-4" />{' '}
                    </p>
                  </Tooltip>
                </li>
                <li className="flex font-bold  text-xl text-[#8E90FF] flex-col justify-center items-start py-7 px-5 w-80">
                  WINNING CHANCES FOR 1 TICKET IN DAILY{' '}
                  <Tooltip
                    message={
                      ' Winning Chances for 1 Ticket , if you have more than 1 ticket multiply by number of tickets to get your current chance.'
                    }
                  >
                    <p className="notranslate font-bold text-xl flex items-center gap-3 text-white">
                      {dailyChance}{' '}
                      <img src={info} alt="info" className="w-4 h-4" />
                    </p>
                  </Tooltip>
                </li>
                <li className="flex font-bold  text-xl text-[#8E90FF] flex-col justify-center items-start py-7 px-5 w-80">
                  WINNING CHANCES FOR 1 TICKET IN WEEKLY{' '}
                  <Tooltip
                    message={
                      ' Winning Chances for 1 Ticket , if you have more than 1 ticket multiply by number of tickets to get your current chance.'
                    }
                  >
                    <p className="notranslate font-bold text-xl flex items-center gap-3 text-white">
                      {weeklyChance}{' '}
                      <img src={info} alt="info" className="w-4 h-4" />
                    </p>
                  </Tooltip>
                </li>
              </ul>
            </div>
            <button
              onClick={setShowDraw}
              className="mx-auto mt-8 flex font-bold sm:text-2xl text-center items-center justify-center  rounded-[39px] bg-[#F00FE8] bg-gradient-to-r from-[#13EBFD] text-md font-tcbregular italic text-[#FFFFFF] mb-12 sm:w-[400px] w-[200px] lg:w-[400px] mx-auto sm:h-[70px] h-[40px]"
            >
              Buy Tickets
            </button>
          </div>
        </div>
      </div>
    </div>

      <div className="bg-gradient-to-b via-[#202e44] from-[#596b87] to-[#596b87]">
        <Suspense>
          <Banner />

          <Logs transactions={txs} />
        </Suspense>
      </div>
    </>
  );
};

export default CRANNI;
